.titleSaveBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploadBannerWrapper {
  display: flex;
  align-items: center;
  gap: 25px;
}

.redirectUrlInput {
  width: 50%;
  margin-left: 15px;
}

.bannerImg {
  max-height: 200px;
  max-width: 90%;
  cursor: pointer;
}

.bannerImgTitle {
  min-width: 112px;
}
