.landingPageTemplatesMainWrapper {
  .titleCreateBtmWrapper {
    display: flex;
    justify-content: space-between;
  }
}

.templateName {
  display: flex;
  align-items: center;
}

.availableWrapper {
  display: flex;
  justify-content: space-around;
}

.actionwrapper {
  display: flex;
  justify-content: space-around;
  .actionItem {
    margin-right: 20px;
    button {
      border-radius: 5px;
    }
  }
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .modalItemWrapper {
    .uploadedFileName {
      display: flex;
      align-items: center;
      padding: 6px;
      border: 2px solid #f18926;
      border-radius: 8px;
      background-color: rgb(217, 149, 85, 0.3);
      p {
        margin: 0;
      }
      span {
        cursor: pointer;
        svg {
          fill: #f18926;
        }
      }
    }
  }
}
