body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f18926 !important;
}

.widgetCard.active {
  border: 1px solid #f18926 !important;
}

.ant-btn-primary {
  background-color: #f18926 !important;
  border: none !important;
}
@import "~antd/dist/antd.css";

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
.clickRowStyl {
  background-color: yellowgreen;
  pointer-events: none;
}

.red {
  background-color: rgb(250, 218, 218);
}
.green {
  background-color: rgb(233, 252, 233);
}
